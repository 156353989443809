import classNames from "classnames"
import { useField } from "formik"
import React, { useState, useEffect } from "react"

import InputError from "./InputError"
import { Color } from "../../../../constants/V2/color"
import Typography from "../Typography"

import { getTextColorClass } from "@utils/V2/color"

// TODO: Whole input, styling and props is 99% the same as Phone number. Look into refactoring to one code

export interface Props {
  id: string
  name: string
  maxLength?: number
  required?: boolean
  disabled?: boolean
  className?: string
  whiteBackground?: boolean
  label?: string
  placeholder?: string
  color?: Color.White | Color.Charcoal
  onFocus?: () => void
  description?: string
  ["data-test-id"]?: string
  type: "text" | "password" | "email"
}

const TextInput = ({
  id,
  name,
  type,
  onFocus,
  className,
  whiteBackground = false,
  label,
  placeholder,
  description,
  color = Color.Charcoal,
  maxLength = 250,
  required = false,
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const [focus, setFocus] = useState(false)
  const [field, meta, helpers] = useField(id)
  const [showPassword, setShowPassword] = useState(false)

  const hasError = meta.touched && meta.error
  const hasValueOrFocus = !!field.value || focus

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames("w-full", className)}>
      <div className="relative w-full rounded-sm">
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              "pointer-events-none absolute left-16-v2 flex gap-8-v2 transition-all duration-200 ease-linear",
              {
                "!top-10-v2": hasValueOrFocus,
              },
              {
                "top-20-v2": !hasValueOrFocus,
              }
            )}
          >
            <Typography
              weight={hasValueOrFocus ? "medium" : "book"}
              font="grotesk"
              color={color}
              text={required ? `${label} *` : label}
              size={hasValueOrFocus ? "subscript-sm" : "body-sm"}
              className={classNames(
                { uppercase: hasValueOrFocus },
                "transition-all duration-200"
              )}
            />
            {description && (
              <Typography
                weight="book"
                font="grotesk"
                text={`(${description})`}
                color={color}
                size={hasValueOrFocus ? "subscript-sm" : "body-sm"}
              />
            )}
          </label>
        )}

        <input
          id={id}
          {...field}
          {...props}
          name={name}
          value={field.value}
          disabled={disabled}
          maxLength={maxLength}
          onBlurCapture={() => setFocus(false)}
          type={type === "password" && showPassword ? "text" : type}
          placeholder={hasValueOrFocus && placeholder ? placeholder : undefined}
          className={classNames(
            whiteBackground ? "bg-white-v2" : "bg-transparent",
            "w-full rounded-sm border px-16-v2 pb-10-v2 pt-[28px] text-body-sm-v2 focus:border-blue-v2",
            getTextColorClass(color),
            { "border-orange-v2": hasError },
            {
              "border-charcoal-v2/20 hover:border-charcoal-v2":
                color === Color.Charcoal && !hasError,
            },
            {
              "border-white-v2/20 hover:border-white-v2":
                color === Color.White && !hasError,
            }
          )}
          onFocus={() => {
            setFocus(true)
            if (onFocus) {
              onFocus()
            }
          }}
        />

        {type === "password" && (
          <span
            onMouseUp={() => setShowPassword(false)}
            onMouseDown={() => setShowPassword(true)}
            className="absolute right-16-v2 top-[22px] cursor-pointer hover:opacity-50"
          >
            <Typography
              text="show"
              weight="book"
              font="grotesk"
              size="body-sm"
              color={color}
            />
          </span>
        )}
      </div>

      {hasError ? (
        <InputError
          error={`* ${meta.error}`}
          data-test-id={`input-error-${props["data-test-id"]}`}
        />
      ) : null}
    </div>
  )
}

export default TextInput
